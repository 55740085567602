import { Injectable } from '@angular/core';
import { ShoppingCartItem } from '../models/shopping-cart-item';


export interface Item{
  item_id: string;
  item_name: string;
  price: number | null;
  quantity: number | null;
}

declare var gtag: any;
declare let fbq: Function;

@Injectable({
  providedIn: 'root'
})
export class MetricsService {

  constructor() { }

  addToCart(itemPrice: number, itemId: number, itemName: string){
    try {
      gtag('event', 'add_to_cart', { currency: 'EUR', value: itemPrice, items: [{ item_id:itemId, item_name: itemName }] });
    } catch {}
  }

  purchase(orderId: string, totalAmount: number, storeId: string, shippingCosts: number, coupon: string, cartItemCollection: ShoppingCartItem[]){
    try {
      gtag('event', 'purchase', { currency: 'EUR', transaction_id: orderId, value: totalAmount, 
      affiliation: storeId, coupon: coupon, shipping: shippingCosts, items: this.mapShoppingCartItemsToItemCollection(cartItemCollection) });
    } catch {}

    try {
      fbq('track', 'Purchase');
    } catch {}
  }

  beginPayment(cartGrandTotal: number, coupon: string, cartItemCollection: ShoppingCartItem[]){
    try {
      gtag('event', 'beginPayment', { currency: 'EUR', value: cartGrandTotal, coupon: coupon, items: this.mapShoppingCartItemsToItemCollection(cartItemCollection) });
    } catch{}
  }

  submitZipCodeEvent(zipCode: string, houseNumber: string, houseNumberAddition: string){
    try {
      gtag('event', 'zipCodeLookup', { data: `${zipCode} ${houseNumber}${houseNumberAddition}`});
    } catch {}
  }

  retrieveCheckoutAddressDetails(zipCode: string, result: boolean){
    try {
      gtag('event', 'retrieveCheckoutAddressDetails', { zipCode: zipCode, result: result });
    } catch {}
  }

  selectItemEvent(itemListId: string, itemListName: string, selectedItemId: string, selectedItemName: string){
    const selectedItem: Item[] = [];
    selectedItem.push({ item_id: selectedItemId, item_name: selectedItemName, price: null, quantity: null });

    try {
      gtag('event', 'select_item', { item_list_id: itemListId, item_list_name: itemListName, items: selectedItem });
    } catch {}
  }

  selectContentEvent(contentType: string, item: string){
    try {
      gtag('event', 'select_content', { content_type: contentType, item_id: item });
    } catch {}
  }

  searchEvent(searchTerm: string){
    try {
      gtag('event', 'search', { search_term: searchTerm });
    } catch {}
  }

  loginEvent() {
    try {
      gtag('event', 'login', { method: 'email' });
    } catch {}
  }

  signUpEvent() {
    try {
      gtag('event', 'sign_up', { method: 'email' });
    } catch {}
  }

  continueAsGuestEvent() {
    try {
      gtag('event', 'continue_as_guest');
    } catch {}
  }

  pageView(page_location: string, page_title: string, user_agent: string) {
    try {
      gtag('event', 'page_view', { page_location: page_location, page_title: page_title, user_agent: user_agent });
    } catch {}
  }

  pageViewFb(){
    try {
      fbq('track', 'PageView');
    } catch {}  
  }

  logException(description: string) {
    try {
      gtag('event', 'exception', { description: description });
    } catch {}
  }

  trackConversion() {
    try {
      gtag('event', 'conversion', {'send_to': 'AW-995917025/8NyaCJit7YkDEOH58doD'});
    } catch {}
  }

  private mapShoppingCartItemsToItemCollection(cartItemCollection: ShoppingCartItem[]){
    return cartItemCollection.map(s=> {
      const item: Item = {
        item_id: s.ProductId.toString(),
        item_name: s.ProductId.toString(),
        price: s.Price,
        quantity: s.Qty
      }
      return item;
    });
  }

}
