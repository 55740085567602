import { Guid } from "guid-typescript";


export class ShoppingCartItem {
    CartItemGuid: string;
    ProductId: number;
    OriginalPrice: number;
    Price: number;
    OldPrice: number | null;
    Qty: number;
    ValidationErrors: string | null;   
    Ratio: number;
    Attributes: AttributeModel[] | null;
    AttributesPriceInfluence: number | null;

    constructor(){
        this.CartItemGuid = Guid.create().toString();
        this.ProductId = 0;
        this.OriginalPrice = 0;
        this.Price = 0;
        this.OldPrice = 0;
        this.Qty = 0;
        this.ValidationErrors = '';
        this.Ratio = 1;
        this.Attributes = null;
        this.AttributesPriceInfluence = null
    }
}

export class AttributeModel {
    Name: string;
    Value: string;
    PriceAdjustment: number;

    constructor (name: string, value: string, priceAdjustment: number) {
        this.Name = name;
        this.Value = value;
        this.PriceAdjustment = priceAdjustment;
    }
}