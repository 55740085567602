import { ElementRef, Injectable } from "@angular/core";
import { BehaviorSubject, forkJoin, Observable, of, ReplaySubject, zip } from "rxjs";
import { concatMap, map } from 'rxjs/operators';
import { CatalogService } from "./catalog.service";
import { StoreService } from "./store.service";
import { ProductAdderModel } from "../models/productAdderModel";
import { StoreDto } from "../interfaces/store.model";
import { ShoppingCartItem, AttributeModel } from '../models/shopping-cart-item';
import { CartState } from "../models/cart/cart-state.model";
import { Catalog, TierPrice } from "../interfaces/catalog";
import { MetricsService } from "./metrics.service";

@Injectable({ providedIn: 'root' })
export class CartService {

    public cartState$: BehaviorSubject<CartState> = new BehaviorSubject<CartState>(this.createDefaultCartState());

    productAdderSubject$:ReplaySubject<ProductAdderModel> = new ReplaySubject<ProductAdderModel>(1);
    cartCollectionChangedSubject$:ReplaySubject<ShoppingCartItem> = new ReplaySubject<ShoppingCartItem>(1);

    paymentMethodId: number | null = null;
    appliedDiscountOnSubTotal: number | null = null;

    constructor(
        private storeService: StoreService,
        private catalogService: CatalogService,
        private metricsService: MetricsService) { }

    get getProductAdderObserver(): Observable<ProductAdderModel> {
        return this.productAdderSubject$.asObservable();
    }

    get getCartCollectionChangedObserver(): Observable<ShoppingCartItem> {
        return this.cartCollectionChangedSubject$.asObservable();
    }

    addProduct(
        id: number,
        qty: number,
        price: number,
        ratio: number,
        imageElementRef: ElementRef | null,
        isScale: boolean,
        emitCartStateEvent: boolean = true,
        attributes: AttributeModel[] | null = null,
        cartItemGuid: string | null = null) {

        // get index of existing cart item
        let existingCartItemIndex = -1;
        if (cartItemGuid) {
            existingCartItemIndex = this.cartState$.value.products.findIndex((_item) => _item.CartItemGuid === cartItemGuid);
        }
        else {
            existingCartItemIndex = this.cartState$.value.products.findIndex((_item) => _item.ProductId === id);
        }

        const isExistingItem = (existingCartItemIndex !== -1 && attributes === null) 
            || (existingCartItemIndex !== -1 && isScale);

        // calculate final qty to process further with
        let finalQty = qty;
        if (isExistingItem) {
            finalQty = this.cartState$.value.products[existingCartItemIndex].Qty + qty;
        }

        const attributesPriceInfluence = attributes && attributes.length > 0 ? attributes.map((item) => item.PriceAdjustment)
            .reduce((sum, current) => sum + current, 0) : 0;

        return this.calculateFinalPrice(id, finalQty, attributesPriceInfluence).pipe(concatMap(finalPrice => {
            const cartItem = new ShoppingCartItem();
            cartItem.ProductId = id;
            cartItem.Qty = finalQty;
            cartItem.Price = finalPrice / ratio;
            cartItem.OriginalPrice = price;
            cartItem.Attributes = attributes && attributes.length > 0 ? attributes : null;
            cartItem.AttributesPriceInfluence = attributesPriceInfluence;
            cartItem.OldPrice = finalPrice !== cartItem.OriginalPrice ? cartItem.OriginalPrice : null;
            cartItem.Ratio = ratio;

            // update cart item qty
            if (isExistingItem) {
                const existingCartItem = this.cartState$.value.products[existingCartItemIndex];
                existingCartItem.Qty = finalQty;
                existingCartItem.Price = finalPrice / ratio;
                existingCartItem.OldPrice = finalPrice !== existingCartItem.OriginalPrice ? existingCartItem.OriginalPrice : null;
            }
            else { // add as new cart item
                this.cartState$.value.products.push(cartItem);
                this.cartCollectionChangedSubject$.next(cartItem);
            }

            // process metrics
            this.metricsService.addToCart(finalPrice / ratio, id, '');

            if (emitCartStateEvent) {
                return this.emitCartStateEvent(imageElementRef);
            }
            else{
                return of();
            }
        }));
    }

    decrease(cartItemGuid: string, qty: number): Observable<void> {
        const existingCartItemIndex = this.cartState$.value.products.findIndex((_item) => _item.CartItemGuid === cartItemGuid);
        if (existingCartItemIndex !== -1) {
            const cartItem = this.cartState$.value.products[existingCartItemIndex];
            const productId = cartItem.ProductId;
            cartItem.Qty -= qty;
            return this.calculateFinalPrice(productId, cartItem.Qty, cartItem.AttributesPriceInfluence).pipe(concatMap(finalPrice => {
                cartItem.Price = finalPrice / cartItem.Ratio;
                cartItem.OldPrice = finalPrice === cartItem.OriginalPrice ? cartItem.OldPrice = null : cartItem.OldPrice;

                // check if qty became zero, if so then remove item from cart
                if (cartItem.Qty === 0) {
                    this.cartState$.value.products = this.cartState$.value.products.filter((_item) => _item.CartItemGuid !== cartItemGuid);
                }
                return this.emitCartStateEvent();
            }));
        }

        return of();
    }

    removeProduct(cartItemGuid: string): Observable<void> {
        this.cartState$.value.products = this.cartState$.value.products.filter((_item) => _item.CartItemGuid !== cartItemGuid);
        return this.calculateCartItems().pipe(concatMap(_=> {
            return this.emitCartStateEvent();
        }));

        //return this.emitCartStateEvent();
    }

    clearCart(): Observable<void> {
        this.paymentMethodId = null;
        this.cartState$.value.products = [];
        return this.emitCartStateEvent();
    }


    public getCartItemQty(productId: number) {
        if (this.cartState$.value.products.length === 0)
            return 0;

        let totals = this.cartState$.value.products.filter((item) => item.ProductId === productId)
            .map((item) => item.Qty || 0)
            .reduce((sum, current) => sum + current, 0);

        return totals;
    }

    public getCartItems() {
        return this.cartState$.value.products || [];
    }

    public getCartItemByGuid(cartItemGuid: string) {
        return this.cartState$.value.products.filter(item => item.CartItemGuid === cartItemGuid)[0];
    }

    public getCartSubTotal(): Observable<number> {
        if (this.cartState$.value.products.length === 0 || this.cartState$.value.products.filter(item => item.Qty > 0).length === 0)
            return of(0);
            
        return of(this.cartState$.value.products.filter((item) => item.Price * item.Qty)
            .map((item) => item.Price * item.Qty)
            .reduce((sum, current) => sum + current, 0));
    }

    public hasCartItems(): boolean {
        return this.cartState$.value.products.length > 0;
    }

    public getCartGrandTotal(): Observable<number> {
        if (this.cartState$.value.products.length === 0 || this.cartState$.value.products.filter(item => item.Qty > 0).length === 0)
            return of(0);

        const subTotal = this.cartState$.value.products.filter((item) => item.Price * item.Qty)
            .map((item) => item.Price * item.Qty)
            .reduce((sum, current) => sum + current, 0);

        return this.getOrderTotalAmountAppliedDiscount().pipe(map((appliedDiscount: number)=>{
          return subTotal - appliedDiscount;
        }));
    }

    public getMinimumOrderAmountAchieved(): Observable<boolean> {
        if (this.cartState$.value.products.length === 0 || this.cartState$.value.products.filter(item => item.Qty > 0).length === 0)
            return of(false);

        let subTotal = this.cartState$.value.products.filter((item) => item.Price * item.Qty)
            .map((item) => item.Price * item.Qty)
            .reduce((sum, current) => sum + current, 0);

        return this.storeService.merchant$.pipe(map(store => {
            if (store!.MinimumOrderAmount === 0) return true;

            return subTotal >= store!.MinimumOrderAmount
        }));
    }

    // this helper method is used to re-calculate all items in cart for instance when the shipping method gets changed in ui
    private calculateCartItems() {
        let calls: any[] = [];
        this.cartState$.value.products.forEach(cartItem => {
            calls.push(this.calculateFinalPrice(cartItem.ProductId, cartItem.Qty, cartItem.AttributesPriceInfluence).pipe(map(finalPrice => {
                cartItem.Price = finalPrice / cartItem.Ratio;
                cartItem.OldPrice = finalPrice === cartItem.OriginalPrice ? cartItem.OldPrice = null : cartItem.OldPrice;
            })));
        });

        const sources = [of(calls)]
        return forkJoin(sources);
    }

    private calculateFinalPrice(productId: number, quantity: number, attributesPriceInfluence: number | null): Observable<number> {

        const catalogObs = this.catalogService.catalogUpdated$;

        return catalogObs.pipe(map((catalog: Catalog) => {
            const product = catalog.Products.filter(product => product.ProductId === productId)[0];
            const tierPrices = product.TierPrices;
            let basePrice = this.catalogService.getSingleProductPrice(product);

            if (!tierPrices || tierPrices.length === 0)
                return basePrice + (attributesPriceInfluence || 0);
            else{
              let previousQty: number = 1;
              let previousPrice: number | null = null;
              for (var tierPrice of tierPrices.sort((a: TierPrice, b: TierPrice) => a.Quantity < b.Quantity ? -1 : 1)) {
                  //check quantity
                  if (quantity < tierPrice.Quantity)
                      continue;
                  if (tierPrice.Quantity < previousQty)
                      continue;
  
                  //save new price
                  previousPrice = tierPrice.Price;
                  previousQty = tierPrice.Quantity;
              }
  
              if (previousPrice)
                  return previousPrice + (attributesPriceInfluence || 0)
              else {
                return basePrice + (attributesPriceInfluence || 0);
              }
            }
        }));
    }

    private emitCartStateEvent(withImageRef?: ElementRef<any> | null) {
        const getCartSubTotalObs = this.getCartSubTotal();
        const getMinimumOrderAmountAchievedObs = this.getMinimumOrderAmountAchieved();
        const appliedDiscountOnSubTotalObs = this.getOrderTotalAmountAppliedDiscount();

        return zip(getCartSubTotalObs, getMinimumOrderAmountAchievedObs, appliedDiscountOnSubTotalObs)
            .pipe(map(([subTotal, minAmount, appliedDiscountOnSubTotal]) => {

              const cartSubject: CartState = <CartState> {
                totalAmount: subTotal,
                minOrderAmountAchieved: minAmount,
                itemCount: this.cartState$.value.products.length,
                imageElementRef: withImageRef ? withImageRef : null,
                appliedDiscountOnSubTotal: appliedDiscountOnSubTotal,
                products: this.cartState$.value.products,
                paymentMethod: 0,
                customerPaymentPreference: '',
                shippingCosts: 0
            };
            this.cartState$.next(<CartState> cartSubject);

        }));
    }

    private getOrderTotalAmountAppliedDiscount(){
        return this.storeService.merchant$.pipe(concatMap((store: StoreDto | null)=>{
            if (store && (store.DiscountOnTotalAmount && store.DiscountOnTotalAmount > 0))
            {
                return this.getCartSubTotal().pipe(map((cartSubTotal: number)=>{
                    const unRoundenValue = cartSubTotal * (store.DiscountOnTotalAmount / 100);
                    return Math.round(unRoundenValue * 100) / 100;
                }));
            }
            else return of(0);
        }));
    }

    private createDefaultCartState(){
      return <CartState>{
        products: <ShoppingCartItem[]>[],
        totalAmount: 0,
        minOrderAmountAchieved: false,
        itemCount: 0,
        paymentMethod: 0,
        shippingCosts: 0,
      }
    }

}

